import { AfterViewInit, Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { skip, take, timeout } from 'rxjs';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements AfterViewInit{
  title = 'zetta-meet';
  appId: null | string = '';
  roomName: null | string = '';
  jwt: null | string = '';

  /**
   * Represents the main component of the application.
   */
  constructor(private _matIconRegistry: MatIconRegistry, private route: ActivatedRoute) {
    
  }

  ngAfterViewInit(): void {
    localStorage.clear();
    this._matIconRegistry.setDefaultFontSetClass('material-icons-round');
    this.route.queryParamMap.pipe(skip(1), take(1)).subscribe((queryParamMap) => {
      this.appId = queryParamMap.get('a');
      this.roomName = queryParamMap.get('r');
      this.jwt = queryParamMap.get('j');

      this.initJAAS();
    });
  }

  /**
   * Initializes the JAAS (Jitsi as A Service) by dynamically adding a script tag to the HTML head.
   * The script source is set to the external_api.js file hosted on the 8x8.vc server.
   * This method requires the `appId`, `roomName`, and `jwt` properties to be set.
   */
  initJAAS() {
    if (this.appId && this.roomName && this.jwt) {
      let node = document.createElement('script');
      node.src = `https://8x8.vc/${this.appId}/external_api.js`;
      node.type = 'text/javascript';
      node.async = true;
      node.onload = this.afterScriptLoaded.bind(this)
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }

  /**
   * Callback function that is executed after the Jitsi Meet script has been loaded.
   * Initializes the Jitsi Meet External API with the specified options.
   */
  afterScriptLoaded() {
    const JitsiMeetExternalAPI = (<any>window).JitsiMeetExternalAPI
    const options = {
      roomName: `${this.appId}/${this.roomName}`,
      parentNode: document.querySelector('#jaas-container'),
      lang: 'fr',
      jwt: this.jwt
    };

    new JitsiMeetExternalAPI("8x8.vc", options);
  }
}
